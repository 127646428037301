// eslint-disable-next-line no-undef
jQuery(document).ready(($) => {
  /* IE popup
  ========================================================================== */

  function checkIfIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      // eslint-disable-next-line no-alert
      alert(
        "For an optimal viewing experience, please choose a different browser."
      );
    }

    return false;
  }

  checkIfIE();

  /* Set VW without horizontal scrollbar
  ========================================================================== */

  function setVw() {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
  }

  window.addEventListener("resize", () => {
    setVw();
  });

  setVw();

  /* Old code
  ========================================================================== */

  $("#gtranslate-2 a:first-of-type img").attr(
    "src",
    "/wp-content/uploads/2018/08/AU.png"
  );

  if ($(".gform_wrapper").length) {
    const { gform } = window;

    gform.addFilter(
      "gform_datepicker_options_pre_init",
      (optionsObj, formId, fieldId) => {
        // Apply to field 2 only

        if (formId == 3 && fieldId == 9) {
          optionsObj.minDate = 0;
          optionsObj.firstDay = 1;
          optionsObj.beforeShowDay = jQuery.datepicker.noWeekends;
        }
        return optionsObj;
      }
    );
  }

  $("#site-navigation .mega-menu-plan-your-trip a").append(
    '<img alt="dark new tab" style="margin-top: 10px; height: 30px;" width="30" height="30" src="/wp-content/uploads/2018/07/dark-new-tab.svg"/>'
  );

  $("#site-navigation .mega-menu-fares-and-tickets a").append(
    '<img alt="dark new tab" style="margin-top: 10px; height: 30px;" width="30" height="30"  src="/wp-content/uploads/2018/07/dark-new-tab.svg"/>'
  );

  $("#site-navigation .mega-menu-passengers a").append(
    '<img style="margin-top: 20px;" alt="passengers" width="196" height="210" src="/wp-content/uploads/2018/12/passengers.png"/>'
  );
  $("#site-navigation .mega-menu-pedestrians a").append(
    '<img style="margin-top: 20px;" alt="pedestrian" width="196" height="213" src="/wp-content/uploads/2019/03/pedestrian.png"/>'
  );
  $("#site-navigation .mega-menu-cyclists a").append(
    '<img style="margin-top: 20px;" alt="cyclist" width="196" height="213" src="/wp-content/uploads/2019/03/cyclist.png"/>'
  );
  $("#site-navigation .mega-menu-drivers a").append(
    '<img style="margin-top: 20px;" alt="driver" width="196" height="213" src="/wp-content/uploads/2019/03/driver.png"/>'
  );

  $(".post-type-archive-light_rail_stops .page-header").append(
    '<p style="text-align: center; margin-bottom: 150px;">There are 13 stops along the 12km track including a terminus at each end of the route.<br>You can find out information about the stop, frequencies and connections to other services by clicking on the name below.</p>'
  );

  $("#site-navigation .mega-menu-accessibility a").append(
    '<div style="text-align: center;"><img style="margin-top: 20px; " alt="accessibility" width="196" height="210"  src="/wp-content/uploads/2018/12/accessibility-new.png"/></div>'
  );

  $(".blog article:first-of-type .post-image").addClass("grid-66 grid-parent");
  $(".blog article:first-of-type .news-content").addClass("grid-33");

  $(
    ".page-id-323 #page-header-left, .page-id-321 #page-header-left, .page-id-325 #page-header-left, .page-id-696 #page-header-left, .page-id-660 #page-header-left, .page-id-1012 #page-header-left"
  ).removeClass("suffix-20");
  $(
    ".page-id-323 #page-header-right, .page-id-321 #page-header-right, .page-id-325 #page-header-right, .page-id-696 #page-header-right, .page-id-660 #page-header-right, .page-id-1012 #page-header-right"
  )
    .removeClass("grid-20")
    .addClass("grid-40");
  $(
    ".page-id-323 .entry-content, .page-id-321 .entry-content, .page-id-1012 .entry-content"
  )
    .addClass("grid-90 grid-parent")
    .css("padding-bottom", "60px");

  $(".page-id-696 .entry-content")
    .addClass("grid-55 grid-parent")
    .css("padding-bottom", "60px");

  $(".page-id-660 .entry-content, .page-id-3 .entry-content")
    .addClass("grid-60 grid-parent")
    .css("padding-bottom", "60px");

  $(".page-id-606 #page-header-left")
    .removeClass("grid-60")
    .addClass("grid-65");
  $(".page-id-606 #page-header-right")
    .removeClass("grid-20")
    .addClass("grid-15");

  $(".page-id-317 #page-header-left").removeClass("suffix-20");
  $(".page-id-317 #page-header-right")
    .removeClass("grid-20")
    .addClass("grid-40");

  $(".page-id-3882 #page-header-left").removeClass("suffix-20");
  $(".page-id-3882 #page-header-right")
    .removeClass("grid-20")
    .addClass("grid-40");

  $(".page-id-327 .entry-content")
    .addClass("grid-90 grid-parent")
    .css("padding-bottom", "60px");
  $(".page-id-327 #page-header-left").removeClass("suffix-20");
  $(".page-id-327 #page-header-right")
    .removeClass("grid-20")
    .addClass("grid-40");

  $(".page-id-650 #page-header-left").removeClass("suffix-20");
  $(".page-id-650 #page-header-right")
    .removeClass("grid-20")
    .addClass("grid-30");
  $(".page-id-650 .entry-content")
    .addClass("grid-75 grid-parent")
    .css("padding-bottom", "60px");

  $(".blog .page-blurb").text("Keep up-to-date with CMET");

  $(".blog #page-header-right").append(
    '<div class="grid-30 prefix-40" style="padding-top: 12px;"><a href="https://www.facebook.com/CanberraMetroLightRail/" target="_blank" rel="noopener"><img src="/wp-content/uploads/2018/08/fb-gray.svg" alt="" width="120px"></a></div><div class="grid-30" style="padding-top: 12px;"><a href="https://www.instagram.com/canberrametrolightrail/" target="_blank" rel="noopener"><img src="/wp-content/uploads/2018/08/ig-gray.svg" alt="" width="120px"></a></div>'
  );

  $(".blog #page-header-left")
    .removeClass("grid-60 suffix-20")
    .addClass("grid-50 suffix-25");

  /* Accordion
  ========================================================================== */

  $(".accordion").on("click", (e) => {
    const acc = e.currentTarget;

    if (!$(acc).hasClass("active")) {
      $(".accordion.active").toggleClass("active").next(".panel").slideToggle();
    }

    $(acc).toggleClass("active").next(".panel").slideToggle();
  });

  /* Google Map
  ========================================================================== */

  const { google } = window;

  async function initMap() {
    // The position of marker
    const position = {
      lat: parseFloat(document.querySelector(".acf-map .marker").dataset.lat),
      lng: parseFloat(document.querySelector(".acf-map .marker").dataset.lng),
    };
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map
    const map = new Map(document.querySelector(".acf-map"), {
      zoom: 16,
      center: position,
      mapId: "24dfce0f3d23e5a1",
    });

    // A marker with a with a URL pointing to a PNG.
    const markerImg = document.createElement("img");

    markerImg.src =
      "https://cmet.com.au/wp-content/uploads/2019/03/stop-pointer-gm.png";

    // The marker
    // eslint-disable-next-line no-unused-vars
    const marker = new AdvancedMarkerElement({
      map,
      position,
      content: markerImg,
    });
  }

  if (document.querySelector(".acf-map")) {
    initMap();
  }

  /* tab
  ========================================================================== */

  $(".tabs .tab-links a").on("click", function (e) {
    const currentAttrValue = $(this).attr("href");

    // Show/Hide Tabs
    $(`.tabs ${currentAttrValue}`).show().siblings().hide();

    // Change/remove current tab to active
    $(this).parent("li").addClass("active").siblings().removeClass("active");

    e.preventDefault();
  });
});
